<template>
<div class="experience-header">
    <h2 class="myexperience-title">{{ $t('myExperience.title') }}</h2>
    <button class="experience-button" @click="reverseExperience">
      <img src="/assets/rotate-arrows.png" alt="rotate_arrows">
    </button>
</div>

<div v-for="(experience, index) in storylineArray" :key="index" class="experience-block">
    <div class="experience-year" :class="{ 'current-job': experience.currently }">{{ experience.year }}</div>
    <div class="experience-details">
        <div class="experience-title" :class="{ 'current-job': experience.currently }">{{ experience.job_title }}</div>
        <a class="experience-website" :href="experience.website" target="_blank">{{ experience.website }}</a>
        <div class="experience-stack">{{ experience.stack }}</div>
    </div>
</div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { ref, watch } from 'vue';

export default {
    name: 'MyExperience',
    setup() {
        const { locale } = useI18n();
        const storyline_en = [
            {
                year: "2021",
                job_title: "Join EPAM Systems as Senior Data Software engineer",
                website: "https://epam.com",
                stack: "Python, PySpark, Spark SQL, Prefect, Terraform, Git, Hadoop, Databricks, AWS",
                currently: true,
            },
            {
                year: 2020,
                job_title: "Join Sparklingtide as Python Software engineer",
                website: "https://sparklingtide.agency/",
                stack: "Python, Django, Postgresql, Docker, Git, Redis, Kafka, Bash",
            },
            {
                year: 2019,
                job_title: "Join cash4brands.ru as Python Software engineer",
                website: "https://cash4brands.ru",
                stack: "Python, Redis, Django, Nginx, Postgresql, Firebase, Mercurial, Bash",
            },
            {
                year: 2018,
                job_title: "Join Flat12 as Python software engineer",
                website: "https://flat12.ru/",
                stack: "Python, Django, Git, JS, PostgreSQL, MODX, PHP, Celery, Nginx",
            },
        ];
        
        const storyline_ru = [
            {
                year: 2021,
                job_title: "Присоединился к EPAM Systems как Senior Data Software engineer",
                website: "https://epam.com",
                stack: "Python, PySpark, Spark SQL, Prefect, Terraform, Git, Hadoop, Databricks, AWS",
                currently: true
            },
            {
                year: 2020,
                job_title: "Присоединился к Sparklingtide как Python Software engineer",
                website: "https://sparklingtide.agency/",
                stack: "Python, Django, Postgresql, Docker, Git, Redis, Kafka, Bash",
            },
            {
                year: 2019,
                job_title: "Присоединился к cash4brands.ru как Python Software engineer",
                website: "https://cash4brands.ru",
                stack: "Python, Redis, Django, Nginx, Postgresql, Firebase, Mercurial, Bash",
            },
            {
                year: 2018,
                job_title: "Присоединился к Flat12 как Python software engineer",
                website: "https://flat12.ru/",
                stack: "Python, Django, Git, JS, PostgreSQL, MODX, PHP, Celery, Nginx",
            },
        ];

        const storylineArray = ref(locale.value === 'ru' ? storyline_ru : storyline_en);

        watch(locale, (newLocale) => {
            storylineArray.value = newLocale === 'ru' ? storyline_ru : storyline_en;
        });

        return {
            storylineArray,
        };
    },
    methods: {
        reverseExperience() {
            this.storylineArray.reverse();
        },
    },

}
</script>

<style>
/* Стили для компонента Мой опыт */
</style>
