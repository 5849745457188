<template>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap">
  <meta property="og:title" content="Andrei Vilenskii Homepage">
  <meta property="og:description" content="Andrei Vilenskii Software engineer homepage">
  <meta property="og:type" content="website">

  
  <div id="app">
    <div class="header">
      <div class="right-buttons-container">
        <button class="language-button" @click="toggleLanguage">
          {{ $t('languageButtonTitle') }}
        </button>
        <div v-if="$route.path === '/'">
          <router-link class="contact-button"  to="/contacts">{{ $t('contactButtonTitle') }}</router-link>
        </div>
        <div v-if="$route.path === '/contacts'">
          <router-link class="contact-button"  to="/">{{ $t('homeButtonTitle') }}</router-link>
        </div>
        <button class="download-button" @click="downloadResume">{{ $t('downloadResume') }}</button>
      </div>
    </div>
    <div class="content">
      <div v-if="$route.path === '/'" class="content-block">
        <div class="left-content-block">
          <WhoAmI />
          <MyTechStack />
        </div>
        <div class="right-content-block" >
          <MyExperience />
        </div>
      </div>
      
      <router-view v-if="$route.path !== '/'"></router-view>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import WhoAmI from './components/WhoAmI.vue';
import MyExperience from './components/MyExperience.vue';
import MyTechStack from './components/MyTechStack.vue';
import resumeFilePath from '@/assets/vilenskii_andrei_resume.pdf';

export default {
  components: {
    WhoAmI,
    MyExperience,
    MyTechStack,
  },
  setup() {
    const { t, locale } = useI18n();

    const toggleLanguage = () => {
      const newLocale = locale.value === 'eng' ? 'ru' : 'eng';
      locale.value = newLocale;
    };
    
    const downloadResume = () => {
      const link = document.createElement('a');
      link.href = resumeFilePath;
      link.download = 'vilenskii_andrei_resume.pdf';
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    };


    return {
      t,
      toggleLanguage,
      downloadResume,
      currentLanguage: locale.value,
    };
  },
  mounted() {
    document.title = "Andrei Vilenskii's homepage";
  }
};
</script>


<style>
ul {
  padding: 0;
  list-style-type: none;
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  padding: 20px;
  width: 100%;
}


.button-container {
  display: flex;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

.language-button {
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #ccc;
  color: #333;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
  transition: background-color 0.3s, color 0.3s;
}

.language-button:hover {
  background-color: #333;
  color: #fff;
}

.content {
  justify-content: space-between;
  margin-top: 50px;
  width: 80%;
}

.left-content-block {
  flex-basis: 48%;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
}

.right-content-block {
  flex-basis: 48%;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
}


.download-button,
.contact-button,
.language-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-right: 10px;
  max-width: 200px; 
  text-decoration: none;
}


.language-button:hover,
.contact-button:hover,
.download-button:hover {
  background-color: #0056b3;
}

.right-buttons-container {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  right: 100px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.section-details {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  font-family: 'Source Code Pro', sans-serif;
}

.who-am-i, .tech-stack {
  text-align: left;
  line-height: 1.5;
  font-family: 'Source Code Pro', sans-serif;
}
.contacts-content {
  text-align: left;
  line-height: 2;
  font-size: 20px;
}

.content-block {
  display: flex;
  justify-content: space-between;
}

.experience-block {
  display: flex;
  margin-bottom: 15px;
  font-family: 'Source Code Pro', sans-serif;
}

.experience-year {
  font-size: 18px;
  font-weight: bold;
  margin-right: 10px;
}

.experience-details {
  display: flex;
  flex-direction: column;
}

.experience-title {
  font-weight: bold;
}

.experience-website {
  font-size: 14px;
  margin-top: 10px;
  text-decoration: none;
  color: black;
}
.experience-stack {
  margin-top: 10px;
}

.current-job {
  font-size: 20px;
  color: #2f52e0; /* Измените цвет на желаемый */
}
.revert-button {
  display: inline-block;
  margin-left: 10px;
  font-size: 24px;
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s;
}

.revert-button:hover {
  color: #0056b3;
}

.arrows-icon-button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  min-width: 50;
  min-height: 50;
}
.experience-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.myexperience-title {
  margin: 0;
}
.experience-button {
  background: none;
  border: none;
  cursor: pointer;
}
.experience-button:hover {
  background-color: #9D9171;
}
</style>