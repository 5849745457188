import { createI18n } from 'vue-i18n'

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {
    eng: {
        whoAmI: {
          title: 'Who Am I',
          firstBlock: 'Hello! My name is Andrei Vilenskii, and I am a creative developer of software code with a passion for crafting amazing virtual worlds. My code is my art, and projects are my canvases.',
          secondBlock: 'My technology stack includes Python, Django, PySpark, Spark SQL, Prefect, Terraform, Git, Hadoop, Databricks, and many other tools. If you are looking for a unique solution that can transform your idea into virtual magic, I am ready to take on this challenge and make your project truly impressive!',
        },
        myExperience: {
          title: 'My Experience',
        },
        myTechStack: {
          title: 'My Tech Stack',
          frameworks: 'Frameworks: Django;',
          coreWeb: 'Core web: HTML/CSS, JavaScript;',
          databases: 'Databases: MySql, PostgreSql, Redis, MongoDB;',
          vcs: 'VCS: Git, Mercurial',
          Linux: 'Linux: Ubuntu',
          bugTracking: 'Bug-tracking: New-relic, Sentry',
          dataSoftwareEngineering: 'Data software engineering: PySpark, Databricks, Hadoop, Prefect, Spark SQL.',
          languages: 'Language: Russian - native, English - B2;',
          taskManagers: 'Task-managers: Jira, Asana;',
          cloudTech: 'Cloud technologies: AWS, Azure;',
          iac: 'IaC: Terraform',
        },
        contacts: {
          phones: {
            georgia: "+995 592 039 125",
            russia: "+7 910 007 09 15",
          },
          linkedin: "",
          email: "andrej-vilenskij@yandex.ru",
          telegram: "https://t.me/IncredibleMiracle",
          tg_channel: "https://t.me/andruha_beton_nn",
        },
        downloadResume: 'Download Resume',
        languageButtonTitle: "Русский",
        contactButtonTitle: "Contact me",
        homeButtonTitle: "Homepage",
        contactsPageTitle: 'Contacts',
        contactsPageContent: 'Contact information goes here.',
      },
    ru: {
        whoAmI: {
            title: 'Кто я',
            firstBlock: 'Приветствую! Меня зовут Андрей Виленский, и я - творческий разработчик программного кода с страстью к созданию удивительных виртуальных миров. Мой код - это мое искусство, а проекты - мои полотна.',
            secondBlock: 'Мой стек технологий включает Python, Django, PySpark, Spark SQL, Prefect, Terraform, Git, Hadoop, Databricks и многие другие инструменты. Если вы ищете уникальное решение, способное преобразовать вашу идею в виртуальную магию, я готов принять этот вызов и сделать ваш проект поистине впечатляющим!', 
        },
        myExperience: {
          title: 'Мой опыт',
        },
        myTechStack: {
            title: 'Мой стэк технологий',
            frameworks: 'Фреймворки: Django;',
            coreWeb: 'Core web: HTML/CSS, JavaScript;',
            databases: 'Базы данных: MySql, PostgreSql, Redis, MongoDB;',
            vcs: 'VCS: Git, Mercurial',
            Linux: 'Линукс: Ubuntu',
            bugTracking: 'Отслеживание багов: New-relic, Sentry',
            dataSoftwareEngineering: 'Data software engineering: PySpark, Databricks, Hadoop, Prefect, Spark SQL.',
            languages: 'Языки: Русский - родной, Английский - B2;',
            taskManagers: 'Менеджеры задач: Jira, Asana;',
            cloudTech: 'Облачные технологии: AWS, Azure;',
            iac: 'IaC: Terraform',
        },
        downloadResume: 'Скачать резюме',
        languageButtonTitle: "English",
        contactButtonTitle: "Контакты",
        homeButtonTitle: "Главная",
        contactsPageTitle: 'Контакты',
        contactsPageContent: 'Здесь размещена контактная информация.',
    }
  }
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default createI18n({
  legacy: false,
  locale: 'eng',
  fallbackLocale: 'eng',
  messages: loadLocaleMessages()
})
