<template>
<div class="who-am-i">
    <h2 class="section-title">{{ $t('whoAmI.title') }}</h2>
    <div class="section-details">
        <p>{{ $t('whoAmI.firstBlock') }}</p>
        <p>{{ $t('whoAmI.secondBlock') }}</p>
    </div>
</div>
</template>

<script>
export default {
name: 'WhoAmI'
};
</script>

<style>
</style>
