<template>
    <div class="contacts-content">
      <div class="content-block">
        <div class="left-content-block">
          <h1>{{ $t('contactsPageTitle') }}</h1>
          <ul>
            <li class="contact-item">
              <span class="contact-name">
                <b>Phone <img src="@/assets/icon_georgia.png" alt="georgian-flag" class="flag"/>:</b>
              </span>
              <span class="contact-info"> <a href="tel:+995592039125">+995 592 039 125</a></span>
            </li>
            <li class="contact-item">
              <span class="contact-name">
                <b>Phone <img src="@/assets/icon_russia.png" alt="russian-flag" class="flag" />:</b>
              </span> 
              <span class="contact-info"> <a href="tel:+79100070915">+7 910 007 09 15</a></span>
            </li>
            <li class="contact-item">
              <span class="contact-name">
                <b>Email:</b>
              </span>
              <span class="contact-info"><a href="mailto:andrej-vilenskij@yandex.ru">andrej-vilenskij@yandex.ru</a></span>
            </li>
            <li class="contact-item">
              <span class="contact-name">
                <b>Telegram:</b>
              </span>
              <span class="contact-info"><a href="https://t.me/IncredibleMiracle" target="_blank">https://t.me/IncredibleMiracle</a></span>
            </li>
            <li class="contact-item">
              <span class="contact-name">
                <b>Telegram channel:</b>
              </span>
              <span class="contact-info">
                <a href="https://t.me/andruha_beton_nn" target="_blank">https://t.me/andruha_beton_nn</a>
              </span>
            </li>
          </ul>
        </div>
        <div class="right-content-block">
          <iframe id="preview" style="border:0px;height:500px;width:500px;margin:5px;box-shadow: 0 0 16px 3px rgba(0,0,0,.2);" src="https://xn--r1a.website/s/andruha_beton_nn"></iframe>
        </div>
      </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: black;
}
.flag {
  width: 20px;
  height: 20px;
}
.contacts-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
  width: 80%;
}
.contact-item {
  display: flex;
  align-items: center;
  font-family: 'Source Code Pro', sans-serif;
}
.contact-name {
  width: 250px;
}
.left-content-block {
  flex-basis: 48%;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
  margin-right: 50px;
}

.right-content-block {
  flex-basis: 48%;
  flex-direction: column;
  align-self: flex-start;
  text-align: left;
}
.content-block {
  display: flex;
  justify-content: space-between;
}

</style>
