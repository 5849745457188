<template>
<div class="tech-stack">
    <h2>{{ $t('myTechStack.title') }}</h2>
    <ul>
        <li>{{ $t('myTechStack.frameworks') }}</li>
        <li>{{ $t('myTechStack.coreWeb') }}</li>
        <li>{{ $t('myTechStack.databases') }}</li>
        <li>{{ $t('myTechStack.vcs') }}</li>
        <li>{{ $t('myTechStack.Linux') }}</li>
        <li>{{ $t('myTechStack.bugTracking') }}</li>
        <li>{{ $t('myTechStack.dataSoftwareEngineering') }}</li>
        <li>{{ $t('myTechStack.languages') }}</li>
        <li>{{ $t('myTechStack.taskManagers') }}</li>
        <li>{{ $t('myTechStack.cloudTech') }}</li>
        <li>{{ $t('myTechStack.iac') }}</li>
    </ul>
</div>
</template>

<script>
export default {
name: 'MyTechStack'
};
</script>

<style>
/* Стили для компонента Мой стэк технологий */
</style>
